import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { motion } from 'framer-motion';

const TestimonialsCarousel = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const images = [
    '/images/testimonial1.jpeg',
    '/images/testimonial2.jpeg',
    '/images/testimonial3.jpeg',
    '/images/testimonial4.jpeg',
    '/images/testimonial5.jpeg',
  ];

  return (
    <div className="bg-gray-800 text-center py-8">
      <motion.h2
        className="text-2xl font-bold mb-4 text-white"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        See what our successful traders say about us!
      </motion.h2>

      {/* Descrição logo abaixo do título */}
      <motion.p
        className="text-gray-300 mb-6 mx-auto max-w-xl px-4 text-lg leading-relaxed"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        We are committed to helping our community master the trading world. 
        Check out some of the amazing results and feedback our traders have 
        shared about their experience with us!
      </motion.p>

      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
      >
        {images.map((image, index) => (
          <div key={index} className="flex justify-center items-center">
            <img
              src={image}
              alt={`Testimonial ${index + 1}`}
              className="max-w-full h-auto mx-auto"
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default TestimonialsCarousel;