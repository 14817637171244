// src/components/FeatureCard.jsx
import React from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames';

const FeatureCard = ({ title, description, icon: Icon, color }) => {
  const cardClasses = classNames(
    {
      'bg-red-700': color === 'red',
      'bg-green-700': color === 'green',
    },
    'p-6 rounded-lg shadow-md hover:shadow-xl transition'
  );

  const iconClasses = classNames(
    {
      'text-red-400': color === 'red',
      'text-green-400': color === 'green',
    },
    'text-4xl mb-4'
  );

  return (
    <motion.div
      className={cardClasses}
      whileHover={{ y: -5 }}
      whileTap={{ scale: 0.95 }}
    >
      <div className={iconClasses}>
        <Icon />
      </div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-300">{description}</p>
    </motion.div>
  );
};

export default FeatureCard;
