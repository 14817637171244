// src/components/HeroSection.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { FaTelegramPlane } from 'react-icons/fa';
import './HeroSection.css';
import CountdownTimer from './CountdownTimer';

const HeroSection = ({
  telegramLink = 'https://t.me/YMTradingSignal', // default
}) => {
  // Define a target date for a 5-minute countdown
  const targetDate = new Date();
  targetDate.setMinutes(targetDate.getMinutes() + 5);

  return (
    <section
      className="hero-section relative flex flex-col items-center justify-center bg-center bg-cover bg-no-repeat text-white px-4 py-16 h-screen"
      style={{ backgroundImage: "url('/images/logo-background.jpg')" }}
    >
      {/* Overlay mais escuro */}
      <div className="absolute inset-0 bg-black opacity-80 sm:opacity-90 md:opacity-[0.95]"></div>
      <div className="relative z-10 text-center max-w-2xl">
        {/* Texto de urgência em amarelo */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
          className="mb-4"
        >
          <p className="text-white text-lg font-bold">
            Hurry up! Limited spots available. Join now and get a bonus entry in our exclusive Group!
          </p>
        </motion.div>

        {/* Contador */}
        <CountdownTimer targetDate={targetDate} />

        {/* Botão CTA */}
        <motion.a
          href={telegramLink}
          target="_blank"
          rel="noopener noreferrer"
          whileHover={{ scale: 1.05, boxShadow: '0 0 20px rgba(0,255,0,0.7)' }}
          whileTap={{ scale: 0.95 }}
          className="inline-flex items-center justify-center bg-green-500 border-2 border-green-300 text-white font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 animate-pulse mt-6"
        >
          <FaTelegramPlane className="mr-2" />
          Join Our Telegram Group
        </motion.a>
      </div>
    </section>
  );
};

export default HeroSection;