// src/components/AboutMe.jsx
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { motion } from 'framer-motion';

const AboutMe = () => {
  // Responsividade do carrossel
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // Lista de TODAS as imagens do anexo, prefixadas com /images
  const images = [
    '/images/IMG_6189.jpg',
    '/images/IMG_6274.jpg',
    '/images/IMG_6308.jpg',
    '/images/R2M04050.jpg',
    '/images/R2M04058.jpg',
    '/images/R2M04195.jpg',
    '/images/Sequência 01.00_01_38_06.Quadro001.jpg',
    '/images/Sequência 01.00_03_06_00.Quadro006.jpg',
    '/images/Sequência 01.00_03_51_18.Quadro013.jpg',
  ];

  return (
    <section id="about-me" className="bg-gray-800 py-16">
      <div className="container mx-auto px-4 flex flex-col items-center text-center">
        <motion.h2
          className="text-3xl md:text-4xl font-semibold text-white mb-6"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          The Millionaire Trader Behind the Mask
        </motion.h2>

        <motion.p
          className="text-lg md:text-xl text-gray-300 leading-relaxed max-w-4xl"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          For the past five years, I have been mastering the trading market,
          becoming a self-made millionaire. Today, my mission is to help others
          escape the "matrix" and achieve financial independence. I wear a mask
          for my safety, as a young millionaire who chose to be rich, not famous.
        </motion.p>

        {/* Carrossel de Imagens */}
        <div className="w-full max-w-4xl mt-8">
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
          >
            {images.map((image, index) => (
              <div key={index} className="flex justify-center items-center">
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className="max-w-full h-auto mx-auto"
                />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
