// src/components/FeaturesSection.jsx
import React from 'react';
import { motion } from 'framer-motion';
import FeatureCard from './FeatureCard';
import { FaChartLine, FaTv, FaTrophy, FaHeadset } from 'react-icons/fa';

const FeaturesSection = () => {
  return (
    <section id="features" className="bg-gray-800 py-16">
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-3xl md:text-4xl font-semibold text-center text-white-600 mb-12"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          Why Choose YM Trading Signals
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <FeatureCard 
            title="5 Daily Sessions" 
            description="Receive five actionable trading sessions every day to maximize your profits." 
            icon={FaChartLine} 
            color="red" 
          />
          <FeatureCard 
            title="Daily Live Streams" 
            description="Join our daily live trading sessions for real-time strategies and insights." 
            icon={FaTv} 
            color="green" 
          />
          <FeatureCard 
            title="Weekly Competitions" 
            description="Participate in weekly competitions with cash prizes to test and reward your skills." 
            icon={FaTrophy} 
            color="red" 
          />
          <FeatureCard 
            title="24/7 Support" 
            description="Get 24/7 support from our dedicated team to assist you anytime." 
            icon={FaHeadset} 
            color="green" 
          />
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;