import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { FaTelegramPlane } from 'react-icons/fa';

import HeroSection from './components/HeroSection';
import FeaturesSection from './components/FeaturesSection';
import AboutMeSection from './components/AboutMeSection';
import TestimonialsCarousel from './components/TestimonialsCarousel';
import GroupInfoSection from './components/GroupInfoSection';
import Footer from './components/Footer';

// Main Landing Page → "/"
const LandingPage = () => {
  console.log('>>> Rendering: LandingPage (/)');
  return (
    <div className="flex flex-col min-h-screen bg-black text-white">
      <main className="flex-grow">
        <HeroSection />
        <FeaturesSection />
        <AboutMeSection />
        <TestimonialsCarousel />
        <GroupInfoSection />
      </main>
      <Footer />
    </div>
  );
};

// Landing Page for "/bot"
const BotLandingPage = () => {
  console.log('>>> Rendering: BotLandingPage (/bot)');
  return (
    <div className="flex flex-col min-h-screen bg-black text-white">
      <main className="flex-grow">
        <HeroSection />
        <FeaturesSection />
        <AboutMeSection />
        <TestimonialsCarousel />
        <GroupInfoSection />
      </main>
      <Footer />
    </div>
  );
};

function App() {
  console.log('>>> Rendering: App.jsx');
  return (
    <>
      <Routes>
        {/* Main Route */}
        <Route path="/" element={<LandingPage />} />
        {/* /bot Route */}
        <Route path="/bot" element={<BotLandingPage />} />
      </Routes>

      {/* Floating CTA Button */}
      <div className="fixed bottom-4 right-4 sm:bottom-6 sm:right-6 z-50">
        <a
          href="https://t.me/YMTradingSignal"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center bg-green-500 border-2 border-green-300 text-white font-semibold p-3 sm:p-4 rounded-full shadow-lg transition duration-300 animate-pulse"
        >
          <FaTelegramPlane size={24} />
        </a>
      </div>
    </>
  );
}

export default App;
