import React from 'react';

const GroupInfoSection = ({
  telegramLink = 'https://t.me/YMTradingSignal', // default
}) => {
  return (
    <section className="py-16 bg-gray-700 text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-semibold text-center mb-8">
          Join Our Exclusive Trading Group
        </h2>
        <p className="text-center mb-8">
          Gain access to real-time trading signals, live streams, and a community of successful traders.
        </p>
        <div className="flex justify-center">
          <a
            href={telegramLink}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center justify-center bg-green-500 border-2 border-green-300 text-white font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 animate-pulse"
          >
            Join Our Telegram Group
          </a>
        </div>
      </div>
    </section>
  );
};

export default GroupInfoSection;